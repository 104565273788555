import React, { useEffect, useState } from "react";
import { ThemeContext, ThemeType } from "./theme-context";

export default function ThemeProvider(props: any) {
  const [theme, setTheme] = useState<ThemeType>("clear");
  const page = document.getElementById('root');

  useEffect(() => {
    page?.setAttribute('class', `page page--${theme}`);
  }, [theme]);

  const onThemeChanged = (e: any) => {
    setTheme(e.matches ? "dark" : "clear");
  };

  useEffect(() => {
    if (window.matchMedia) {
      if (
          window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        setTheme("dark");
      }

      window
          .matchMedia("(prefers-color-scheme: dark)")
          .addEventListener("change", onThemeChanged);

      return () => {
        window
            .matchMedia("(prefers-color-scheme: dark)")
            .removeEventListener("change", onThemeChanged);
      };
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ current: theme, setTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
