import route from "hocs/route";
import { lazy } from "react";

export interface UGCRequestRouteParams {
  slug: string;
}

export const UGCRequestRoute = route({
  path: "/ugc-request/:slug",
  component: lazy(
    () => import(/* webpackChunkName: "ugc-request" */ "pages/ugc-request")
  ),
});

export const TermsRoute = route({
  path: "/terms",
  component: lazy(() => import(/* webpackChunkName: "terms" */ "pages/terms")),
});

export const PrivacyPolicyRoute = route({
  path: "/privacy-policy",
  component: lazy(
    () =>
      import(/* webpackChunkName: "privacy-policy" */ "pages/privacy-policy")
  ),
});

export const CookiePolicyRoute = route({
  path: "/cookie-policy",
  component: lazy(
    () => import(/* webpackChunkName: "cookie-policy" */ "pages/cookie-policy")
  ),
});

export const ImpressumRoute = route({
  path: "/impressum",
  component: lazy(
    () => import(/* webpackChunkName: "impressum" */ "pages/impressum")
  ),
});
