import React, {useContext} from "react";

export type ThemeType =  "clear" | "dark";

export interface ThemeContext {
  current: ThemeType;
  setTheme: (theme: ThemeType) => void;
}

export const ThemeContext = React.createContext<ThemeContext>({
  current: "clear",
  setTheme() {
    console.log("You are do not using theme context now");
  },
});

export function useTheme() {
  return useContext(ThemeContext).current;
}
