import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import { useTheme } from "components/theme/theme-context";
import { getHeight } from "helpers/elements";

export default function Header() {
  const [topIndent, setTopIndent] = useState<number>(0);
  const theme = useTheme();
  const clearTheme = theme === "clear";
  const ref = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    if (clearTheme) {
      setTopIndent(getHeight(ref.current));
    }
  }, [theme]);

  return (
    <>
      <header
        className={clsx({
          "float-header gray-header j-float-header _fixed": clearTheme,
          header: !clearTheme,
        })}
        ref={ref}
      >
        <div className="header-main">
          <div className="iwrap">
            <div className="header-inner">
              <a href="#root" className="header-logo">
                <span
                  className={"header-logo__img"}
                />
              </a>
            </div>
          </div>
        </div>
      </header>
      {clearTheme && <div style={{ paddingTop: topIndent }} />}
    </>
  );
}