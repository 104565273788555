import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line
import './configure-i18n';
import client from './configure-client-context';

import App from './App';
import { ClientContextProvider } from 'react-fetching-library';
import ThemeProvider from 'components/theme/theme-provider';
import NotFoundPage from "./pages/ugc-request/steps/not-found";
import { isBot } from 'helpers/user-agent';


ReactDOM.render(
    <React.StrictMode>
        <ClientContextProvider client={client}>
            <ThemeProvider>
                {isBot() ? <NotFoundPage/> : <App/> }
            </ThemeProvider>
        </ClientContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
