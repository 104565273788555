import Footer from "components/layout/footer";
import {
  UGCRequestRoute,
  TermsRoute,
  PrivacyPolicyRoute,
  CookiePolicyRoute,
  ImpressumRoute,
} from "config/routes";
import React, {Suspense, useContext, useEffect} from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import Header from "./components/layout/header";
import Cookies from "./components/cookies";
import "./assets/css/main.css";
import {isMobileDevice} from "./helpers/user-agent";
import RuptlyRedirect from "./components/ruptly-redirect";
import {ThemeContext,} from "./components/theme/theme-context";

export default function App() {
  const {current} = useContext(ThemeContext);
  useEffect(() => {
    if (isMobileDevice()) {
      document.body.classList.add('is-mobile');
    }
  }, [])

  useEffect(() => {
    document.body.setAttribute('data-mode', current);
  }, [current]);


  return (
      <Router>
        <Cookies/>
        <Header/>
        <Suspense fallback={<div/>}>
          <Switch>
            <UGCRequestRoute/>
            <TermsRoute/>
            <CookiePolicyRoute/>
            <PrivacyPolicyRoute/>
            <ImpressumRoute/>
            <RuptlyRedirect/>
          </Switch>
        </Suspense>
        {/* {!isAuthorized ? <SignInPage /> : null} */}
        <Footer/>
      </Router>
  );
}
