import { useState } from "react";

type UseBooleanReturn = [boolean, () => void, () => void, () => void];

export default function useBool(defaultState = false): UseBooleanReturn {
    const [state, setState] = useState(defaultState);
    const setTrue = () => {
        setState(true);
    };
    const setFalse = () => {
        setState(false);
    };
    const toggle = () => {
        setState(!state);
    };

    return [state, setTrue, setFalse, toggle];
}
