import React from "react";
import { getUrlFromPath } from "helpers/strings";
import { Route } from "react-router-dom";

interface IRouteProps {
    path: string;
    component: any;
    exact?: boolean;
    strict?: boolean;
}

export default function route<P = void>(defaultProps: IRouteProps) {
    function getLink(params?: P) {
        return getUrlFromPath(defaultProps.path, params);
    }
    return class extends React.Component<IRouteProps> {
        static path = defaultProps.path;
        static exact = defaultProps.exact;
        static strict = defaultProps.strict;

        static defaultProps = defaultProps;

        static getLink = getLink;
        static l = getLink;

        render() {
            const { component: Component, ...props } = this.props;

            return <Route component={Component} {...props} />;
        }
    };
}
