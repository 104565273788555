import React, {useEffect} from "react";

const RuptlyRedirect = () => {
  useEffect(() => {
    // window.location.href = 'https://ruptly.tv';
  }, []);

  return <></>;
}

export default RuptlyRedirect;