import React, { useRef, useEffect } from 'react';

function useOutsideClick(ref: any, handlerClick: () => void): void {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            if (ref.current && !ref.current.contains(event.target)) {
                handlerClick();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, handlerClick]);
}

interface IClickOutside {
    onClick: () => void;
    children: JSX.Element | JSX.Element[];
    className?: string;
    style?: React.CSSProperties;
}

export default function ClickOutside(props: IClickOutside) {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    useOutsideClick(wrapperRef, props.onClick);

    return (
        <div ref={wrapperRef} className={props.className} style={props.style}>
            {props.children}
        </div>
    );
}
