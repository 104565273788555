import React from 'react';
import clsx from "clsx";
import {useForm} from "react-final-form";

export default function CookieToggle({
   disabled = false,
   name = ''
}) {
  const {change, getState} = useForm();
  const {values: status} = getState();

  function enabledHandle(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    change(name,true);
  }

  function disabledHandle(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    change(name,false);
  }

  return (
      <div className="ae-sort">
        <a
            className={clsx(
                'ae-sort__item',
                {_active: status[name]}
            )}
            href="#"
            onClick={enabledHandle}
        >
          Enabled
        </a>
        <a
            className={clsx(
                'ae-sort__item',
                {
                  _active: !status[name] && !disabled,
                  _disabled: disabled
                }
            )}
            href="#"
            onClick={disabledHandle}
        >
          Disabled
        </a>
      </div>
  );
}