import React, {useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import {fadeIn, fadeOut} from "helpers/animations";
import clsx from "clsx";
import ClickOutside from "../outside-click";

const CONTAINER: HTMLElement | null =
    document.querySelector("#dialog_container");
const OVERLAY: HTMLElement | null = document.querySelector(".overlay");

interface DialogProps {
  isOpen: boolean;
  title: React.ReactChild;
  text: string;
  confirmText?: string;
  cancelText?: string;
  onSuccess?: () => void;
  onCancel: () => void;
  children?: React.ReactChild;
  dialogClassName?: string;
  contentClassName?: string;
  formClassName?: string;
  withScrollToButtons?: boolean;
}

Dialog.defaultProps = {
  confirmText: "Save",
  cancelText: "Cancel",
  onCancel: () => {
  },
};

let observer: IntersectionObserver;

export default function Dialog(props: DialogProps) {
  const buttonsRef = useRef<HTMLDivElement>(null);
  const scrollBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (props.isOpen) {
      document.body.style.overflow = 'hidden';
      fadeIn(OVERLAY).catch(() => {});
      fadeIn(CONTAINER, {display: "flex"}).catch(() => {});

      if (props.withScrollToButtons) {
        observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            scrollBtnRef.current!.style.display = entry.isIntersecting ? 'none' : 'block';
          })
        }, {
          root: CONTAINER,
          rootMargin: '0px',
          threshold: 0.1
        });
        observer.observe(buttonsRef.current!);
      }
    } else {
      if (props.withScrollToButtons) {
        observer?.disconnect();
      }
      document.body.style.overflow = '';
      fadeOut(OVERLAY).catch(() => {});
      fadeOut(CONTAINER).catch(() => {});
    }
  }, [props.isOpen]);

  if (!CONTAINER) {
    return null;
  }

  const handleBottomScroll = () => {
    buttonsRef.current!.scrollIntoView({behavior: 'smooth'});
  }

  return ReactDOM.createPortal(
      props.isOpen ? (
          <ClickOutside onClick={props.onCancel}>
            <div
                className={clsx(
                    "dialog",
                    props.dialogClassName,
                )}
            >
              <div
                  className={clsx(
                      "dialog-content",
                      props.contentClassName
                  )}
              >
                <span className="dialog__title">{props.title}</span>
                <div className="dialog-text">
                  <p>{props.text}</p>
                </div>
                <div className={clsx("dialog-form", props.formClassName)}>
                  {props.children}
                </div>
                {
                  props.withScrollToButtons &&
                    <button
                        type="button"
                        className="button button--down button--free cookies-settings__bottom"
                        onClick={handleBottomScroll}
                        ref={scrollBtnRef}
                    >
                      <span className="i-down" />
                    </button>
                }
                <div className="dialog-buttons" ref={buttonsRef}>
                  <div className="dialog-button">
                    <button
                        type="button"
                        className="button button--black"
                        onClick={props.onCancel}
                    >
                      {props.cancelText}
                    </button>
                  </div>
                  {props.onSuccess && (
                      <div className="dialog-button">
                        <button
                            type="button"
                            className="button"
                            onClick={props.onSuccess}
                        >
                          {props.confirmText}
                        </button>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </ClickOutside>
      ) : null,
      CONTAINER!
  );
}
