import React, {useEffect, useState} from "react";
import useBool from "hooks/use-bool";
import Dialog from "components/popup/dialog";
import {Form} from "react-final-form";
import {AccordionItem} from "components/accordion";
import "./cookies-styles.css";
import {Checkbox} from "components/form";
import {Link} from "react-router-dom";
import Cookies from 'js-cookie'
import CookieToggle from "./cookies-toggle";

const ALL_COOKIES = {
  cookies_strictly_necessary: true,
  cookies_performance: true,
  cookies_website_functionality: true,
  cookies_marketing: true,
};

function getDefaultAccepted() {
  const cookies = Cookies.get('acceptedCookies');
  return cookies ? JSON.parse(cookies) : null;
}

export default function CookiesModal() {
  const [opened, open, close] = useBool(false);
  const [accepted, setAccepted] = useState<null | typeof ALL_COOKIES>(() => getDefaultAccepted());

  const onAcceptedCookies = (list: typeof ALL_COOKIES) => {
    const acceptedCookies = JSON.stringify(list);
    Cookies.set('acceptedCookies', acceptedCookies, {expires: 365});
    setAccepted(list);
    close();
  };

  return (
      <div
          className="cookies__content"
          style={{display: accepted ? "none" : "block"}}
      >
        <div className="cookies__wrap">
          <h5 className="cookies__title">Cookie Notice</h5>
          <p className="cookies__text">
            The website uses cookies. Some are necessary to help our website work
            properly and can't be switched off, and some are optional but can
            optimise your browsing experience. To manage your cookies choices,
            click on Cookie Settings.
          </p>
          <div className="cookies__buttons">
            <button className="cookies__button-gray" onClick={open}>
              Cookie settings
            </button>
            <button
                className="cookies__button-main"
                onClick={() => onAcceptedCookies(ALL_COOKIES)}
            >
              Accept all
            </button>
          </div>
        </div>

        <Form onSubmit={onAcceptedCookies} initialValues={ALL_COOKIES}>
          {({handleSubmit}) => {
            return (
                <Dialog
                    isOpen={opened}
                    title={<span className="dialog__title--cookies">Cookie Settings</span>}
                    text="We describe the categories of cookies Ruptly and its contractors use below"
                    confirmText="Save and Accept"
                    onCancel={close}
                    onSuccess={handleSubmit}
                    dialogClassName="dialog--wrap"
                    contentClassName="dialog-content--wrap"
                    withScrollToButtons
                >
                  <form onSubmit={handleSubmit} id="cookiesConfig" name="cookiesForm" className="cookies-settings">
                    <p className="cookies-settings__text">
                      Where the organisation setting the cookie provides an
                      automated disabling tool in respect of its cookie(s) we list
                      the name of that organisation, the category of cookies it sets
                      together with a link to its automated disabling tool. In all
                      other cases, we list the names of the cookies themselves and
                      their source so that you can easily identify and disable them,
                      if you want, through your browser controls.
                    </p>
                    <p className="cookies-settings__text">
                      After your initial visit to this website we may change the
                      cookies we use. This policy will be updated and will always
                      give you the means to know who is setting cookies, for what
                      purpose they are used and the means to disable them so you
                      should check it from time to time.
                    </p>
                    <AccordionItem
                        title="Strictly necessary cookies"
                        open={true}
                        paragraph={
                          <div>
                            <p>
                              These cookies are essential in order to enable you to
                              move around the website and use its features.
                            </p>
                            <p>
                              Without these cookies, services you have asked for (such
                              as navigating between pages) cannot be provided. Our
                              sites currently use the following strictly necessary
                              cookies.
                            </p>
                            <CookieToggle name="cookies_strictly_necessary" disabled/>
                            <p>
                              Go to <Link to="/cookie-policy" onClick={close}>Cookie policy</Link> to
                              learn more
                            </p>
                          </div>
                        }
                    />
                    <AccordionItem
                        title="Performance cookies"
                        paragraph={
                          <div>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing elit.
                              Porro pariatur aliquam rem aut laborum quia accusantium
                              repellat nobis odio, non dolore recusandae dolorem autem
                              quo dignissimos, blanditiis rerum animi ipsa.
                            </p>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing elit.
                              Nulla obcaecati odio error eveniet voluptatem ipsam
                              nihil accusamus, voluptatum dolorem eum repellendus
                              beatae laboriosam. Odio fugit ipsam vitae nostrum,
                              maxime dignissimos?
                            </p>
                            <CookieToggle name="cookies_performance"/>
                            <p>
                              Go to <Link to="/cookie-policy" onClick={close}>Cookie policy</Link> to
                              learn more
                            </p>
                          </div>
                        }
                    />
                    <AccordionItem
                        title="Website functionality cookies"
                        paragraph={
                          <div>
                            <p>
                              We make use of cookies to provide you with certain
                              functionality. For example, to remember choices you make
                              (such as your user name, l anguage or the region you are
                              in) and to provide enhanced, more personal features.
                              These cookies are not used to track your browsing on
                              other sites.
                            </p>
                            <CookieToggle name="cookies_website_functionality"/>
                            <p>
                              Go to <Link to="/cookie-policy" onClick={close}>Cookie policy</Link> to
                              learn more
                            </p>
                          </div>
                        }
                    />
                    <AccordionItem
                        title="Marketing cookies"
                        paragraph={
                          <div>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing elit.
                              Porro pariatur aliquam rem aut laborum quia accusantium
                              repellat nobis odio, non dolore recusandae dolorem autem
                              quo dignissimos, blanditiis rerum animi ipsa.
                            </p>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing elit.
                              Nulla obcaecati odio error eveniet voluptatem ipsam
                              nihil accusamus, voluptatum dolorem eum repellendus
                              beatae laboriosam. Odio fugit ipsam vitae nostrum,
                              maxime dignissimos?
                            </p>
                            <CookieToggle name="cookies_marketing"/>
                            <p>
                              Go to <Link to="/cookie-policy" onClick={close}>Cookie policy</Link> to
                              learn more
                            </p>
                          </div>
                        }
                    />
                  </form>
                </Dialog>
            );
          }}
        </Form>
      </div>
  );
}
