import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-main">
        <div className="footer-menu-item">
          <Link
            to="/privacy-policy"
            className="footer-menu__link"
            target="_blank"
          >
            Privacy policy
          </Link>
        </div>
        <div className="footer-menu-item">
          <Link to="/terms" className="footer-menu__link" target="_blank">
            Terms of Service
          </Link>
        </div>

        <div className="footer-menu-item">
          <Link
            to="/cookie-policy"
            className="footer-menu__link"
            target="_blank"
          >
            Cookie policy
          </Link>
        </div>
        <div className="footer-menu-item">
          <Link to="/impressum" className="footer-menu__link" target="_blank">
            Impressum
          </Link>
        </div>
      </div>
    </footer>
  );
}
