export function getUrlFromPath(path: string, params: any = {}): string {
    return path
        .replace(/:([\w_-]+)/g, (...args: any[]) => {
            return params && params[args[1]]
                ? String(params[args[1]])
                : String(args[0]);
        })
        .replace(/\/\//g, '/')
        .replace(/\(.*:.*\)\?/g, '')
        .replace(/[()?]/g, '');
}

export const fillEmptyStr = (
    ...list: (undefined | null | string)[]
): string => {
    const str = list.reduce((prev, curr) => {
        return curr ? `${prev} ${curr}` : prev;
    }, '') as string;

    return str.length > 0 ? str.trim() : 'n/a';
};
