import {
    createClient,
    // createCache,
    RequestInterceptor,
    // QueryResponse,
    // Action,
} from "react-fetching-library";
import qs from "qs";

// const cache = createCache<QueryResponse<any>>(
//     (action: Action) => {
//         return action.method === "GET";
//     },
//     (response: QueryResponse<any> & { timestamp: number }) => {
//         return new Date().getTime() - response.timestamp < 10000;
//     }
// );

const requestHostInterceptor: (host: string) => RequestInterceptor = (
    host
) => () => async (action) => {
    return {
        credentials: "include",
        ...action,
        endpoint: `${host}/api/v1${action.endpoint}${
            action.config?.search
                ? "?" + qs.stringify(action.config.search)
                : ""
        }`,
    };
};

// const responsePayloadInterceptor = () => async (
//     action: Action,
//     response: any
// ) => {
//     const { extractContent = (p: any) => p } = action.config || {};
//     let payload = extractContent(response.payload);
//     return {
//         ...response,
//         payload,
//     };
// };

export default createClient({
    requestInterceptors: [
        requestHostInterceptor("https://ugc-api.ruptly.tv"),
    ],
    responseInterceptors: [],
    // cacheProvider: cache,
});
