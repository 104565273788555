import React from "react";

export default function NotFoundPage() {

  return (
      <section className="content">
        <div className="careers">
          <div className="step-content">
            <div className="step-content__inner">
              <div className="step-content__title">
                <span className="text--large">Not found</span>

                <span className="careers__subtitle">
                  Page or file not found or does not exist.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
