interface FadeInConfig {
    duration?: number;
    display?: 'flex' | 'block';
}
export function fadeIn(el: HTMLElement | null, config?: FadeInConfig) {
    const { duration = 200, display = 'block' } = config || {};
    return new Promise((resolve, reject) => {
        try {
            if (el) {
                let opacity = 0;
                el.style.opacity = opacity.toString();
                el.style.display = display;
                let timer = setInterval(function () {
                    if (opacity === 1) {
                        clearInterval(timer);
                        el.style.visibility = 'visible';
                        resolve();
                    }
                    el.style.opacity = opacity.toString();
                    opacity = Math.round(opacity * 10 + 1) / 10;
                }, duration / 10);
            } else {
                reject();
            }
        } catch (err) {
            reject(err);
        }
    });
}

export function fadeOut(el: HTMLElement | null, ms: number = 200) {
    return new Promise((resolve, reject) => {
        try {
            if (el) {
                let opacity = 1;
                el.style.opacity = opacity.toString();
                let timer = setInterval(function () {
                    if (opacity === 0) {
                        clearInterval(timer);
                        el.style.display = 'none';
                        el.style.visibility = 'hidden';
                        resolve();
                    }
                    el.style.opacity = opacity.toString();
                    opacity = Math.round(opacity * 10 - 1) / 10;
                }, ms / 10);
            } else {
                reject();
            }
        } catch (err) {
            reject(err);
        }
    });
}
