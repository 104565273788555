import React, { useState, ReactNode } from "react";
import "./accordion.styles.css";
import clsx from "clsx";
interface AccordionItemProps {
  paragraph: ReactNode;
  title: string;
  open?: boolean;
}

export const AccordionItem = (props: AccordionItemProps) => {
  const [opened, setOpened] = useState(props.open || false);
  return (
    <div
      className={clsx('accordion-item', opened && 'accordion-item--opened')}
    >
      <div className="accordion-item__line" onClick={() => setOpened(prev => !prev)}>
        <h3 className="accordion-item__title">{props.title}</h3>
        <span className="accordion-item__icon" />
      </div>
      <div className="accordion-item__inner">
        <div className="accordion-item__content">
          <div className="accordion-item__paragraph">{props.paragraph}</div>
        </div>
      </div>
    </div>
  );
};
